import React from "react";
import SEO from "../components/seo";
import FunctionPageLayout from "../components/functionPageLayout";
import VideoTile from "../components/videoTile";

const Wideo = () => {
  return (
    <FunctionPageLayout
      title="Materiały video"
      headline="Zobacz na własne oczy, jak prosty jest QuickCRM"
    >
      <SEO
        title="Materiały video - zobacz prosty system CRM"
        description="Zobacz na własne oczy, jak prosty jest QuickCRM! • Materiały video o CRM • 30 dni za darmo!"
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        <VideoTile
          title="Prosty CRM — wprowadzenie"
          facebookIframe={`<iframe style="border: none; overflow: hidden;" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FsystemQuickCRM%2Fvideos%2F509291736078396%2F&amp;show_text=1&amp;width=560" scrolling="no" allowfullscreen="allowfullscreen" frameborder="0"></iframe>`}
        />
        <VideoTile
          title="Zarządzanie szansami sprzedaży"
          facebookIframe={`<iframe style="border: none; overflow: hidden;" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FsystemQuickCRM%2Fvideos%2F506361793038057%2F&amp;show_text=1&amp;width=560" scrolling="no" allowfullscreen="allowfullscreen" frameborder="0"></iframe>`}
        />
        <VideoTile
          title="Komunikator"
          facebookIframe={`<iframe style="border: none; overflow: hidden;" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FsystemQuickCRM%2Fvideos%2F538007396540163%2F&amp;show_text=1&amp;width=560" scrolling="no" allowfullscreen="allowfullscreen" frameborder="0"></iframe>`}
        />
        <VideoTile
          title="Kalendarz i zadania"
          facebookIframe={`<iframe style="border: none; overflow: hidden;" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FsystemQuickCRM%2Fvideos%2F530773710596865%2F&amp;show_text=1&amp;width=560" scrolling="no" allowfullscreen="allowfullscreen" frameborder="0"></iframe>`}
        />
        <VideoTile
          title="Dynamiczny pulpit pracownika"
          facebookIframe={`<iframe style="border: none; overflow: hidden;" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FsystemQuickCRM%2Fvideos%2F519366085070961%2F&amp;show_text=1&amp;width=560" scrolling="no" allowfullscreen="allowfullscreen" frameborder="0"></iframe>`}
        />
      </div>
    </FunctionPageLayout>
  );
};

export default Wideo;
