import React from "react";
import styles from "./videoTile.module.css";

const VideoTile = ({ title, facebookIframe }) => {
  return (
    <div className={styles.wrapper}>
      <h3>{title}</h3>
      <div
        className={styles.videoResponsive}
        dangerouslySetInnerHTML={{
          __html: facebookIframe
        }}
      />
    </div>
  );
};

export default VideoTile;
